
		import React from 'react';

		function LockIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 448, 512">
					<path d="M384 223.1l-32 0V127.1c0-70.58-57.41-127.1-128-127.1s-128 57.42-128 127.1v95.1L64 223.1c-35.35 0-64 28.65-64 64v160c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64v-160C448 252.7 419.3 223.1 384 223.1zM112 127.1c0-61.75 50.25-112 112-112s112 50.25 112 112v96h-224V127.1zM432 447.1c0 26.47-21.53 48-48 48H64c-26.47 0-48-21.53-48-48v-160c0-26.47 21.53-47.1 48-47.1h320c26.47 0 48 21.53 48 47.1V447.1z" />
				</svg>
			);
		}

		export default LockIcon;
	