import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import PageHeader from '../../components/PageHeader';
import SectionLeftRight from '../../components/SectionLeftRight';
import WebhostingPricing from '../../components/WebhostingPricing';
import ColumnPricing from '../../components/ColumnPricing';
import FeaturesGrid from '../../components/FeaturesGrid';
import Section from '../../components/Section';
import Grid from '../../components/Styles/Grid';
import Typography from '../../components/Styles/Typography';
import CardFeature from '../../components/CardFeature';

import ShieldIcon from '../../components/Icons/Fa/Thin/ShieldIcon';
import UserHeadsetIcon from '../../components/Icons/Fa/Thin/UserHeadsetIcon';
import GaugeMaxIcon from '../../components/Icons/Fa/Thin/GaugeMaxIcon';

import AnglesUpIcon from '../../components/Icons/Fa/Thin/AnglesUpIcon';
import ClockRotateLeftIcon from '../../components/Icons/Fa/Thin/ClockRotateLeftIcon';
import ArrowsRotateIcon from '../../components/Icons/Fa/Thin/ArrowsRotateIcon';
import ServerIcon from '../../components/Icons/Fa/Thin/ServerIcon';
import ShieldVirusIcon from '../../components/Icons/Fa/Thin/ShieldVirusIcon';
import LockIcon from '../../components/Icons/Fa/Thin/LockIcon';

import ChartNetworkIcon from '../../components/Icons/Fa/Thin/ChartNetworkIcon';
import GlobeIcon from '../../components/Icons/Fa/Thin/GlobeIcon';
import DatabaseIcon from '../../components/Icons/Fa/Thin/DatabaseIcon';

import { hostingPlans } from '../../data/webHosting';

const HostingPage = (props) => {
  const { data } = props;
  return (
    <Layout>
      <SEO title="Fully Managed Web Hosting in Boston" />
      <PageHeaderStyles
        heading="<span class='highlight'>Fully Managed</span> Web Hosting "
        subHeading="Let us worry about your website so you can get back to business."
        buttonText="Request a Quote"
        buttonLink="/contact/"
      />

      <SectionLightBlue topDivider={true} bottomDivider={true}>
        <Typography as="h2" textAlign="center">
          You Depend on the Web. You Deserve a Host You Can Depend on.
        </Typography>
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap={4}
        >
          <CardFeature
            heading="Security"
            text="Providing a secure environment for your website with Regular Backups, Server Maintenance, SSL, Malware Scans, and 24/7 Monitoring."
            icon={<ShieldIcon />}
          />
          <CardFeature
            heading="Performance"
            text="We make sure your site is as fast as possible by giving you your own deticated resources on a performance optimized server."
            icon={<GaugeMaxIcon />}
          />
          <CardFeature
            heading="Support"
            text="We try to catch issues before they become a problem...but if you do need help get support, fast, from a real person, with direct access to your server."
            icon={<UserHeadsetIcon />}
          />
        </Grid>
      </SectionLightBlue>

      <WebhostingPricing>
        {hostingPlans.map((data) => (
          <ColumnPricing
            heading={data.heading}
            text={data.text}
            listItems={data.listItems}
            price={data.price}
          />
        ))}
      </WebhostingPricing>

      <SectionLightBlue topDivider={true} bottomDivider={true}>
        <Typography as="h2" textAlign="center">
          Features included in all plans
        </Typography>
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap={4}
        >
          <CardFeature
            heading="24/7 Uptime Monitoring"
            text="We monitor your site 24/7 for any issues, so we can start to get it back online before anyone else notices."
            icon={<AnglesUpIcon />}
          />
          <CardFeature
            heading="Weekly Server Backups"
            text="We don't just backup your website files, we back up the entire server."
            icon={<ClockRotateLeftIcon />}
          />
          <CardFeature
            heading="Updates"
            text="Regular updates are required to ensure the performance and security of your website."
            icon={<ArrowsRotateIcon />}
          />
          <CardFeature
            heading="Dedicated VPS"
            text="All websvites are set up as their own Virtual Private Server. Stop sharing resources with 20+ other websites on the same server."
            icon={<ServerIcon />}
          />
          <CardFeature
            heading="Hacked Website Repair"
            text="If your website is ever hacked, don't panic we will have your website up and running again in no time, usually at no cost to you."
            icon={<ShieldVirusIcon />}
          />
          <CardFeature
            heading="SSL Certificates"
            text="Free SSL Certificates Powered by Let's Encrypt. No more wasting $65+ a year to secure your website."
            icon={<LockIcon />}
          />
        </Grid>
      </SectionLightBlue>

      <Section>
        <Typography as="h2" textAlign="center">
          Additional Features Available
        </Typography>
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap={4}
        >
          <CardFeature
            heading="Load Balancer"
            text="Getting tons of traffic? Distribute traffic to mulitple servers to prevent downtime from too many requests overwhelming a single server."
            icon={<ChartNetworkIcon />}
          />
          <CardFeature
            heading="CDN"
            text="Increase performance, relaiblity and security by using a content delivery network (CDN) to provide fast delivery of your sites files around the globe."
            icon={<GlobeIcon />}
          />
          <CardFeature
            heading="Dedicated Database"
            text="By keeping your database seprate from your webserver you increase your level of data security and flexibility."
            icon={<DatabaseIcon />}
          />
        </Grid>
      </Section>
    </Layout>
  );
};

const SectionGradient = styled(SectionLeftRight)`
  color: white;
  background: var(--linearGradientMixed);
  & button {
    background: var(--lightGreen);
    color: black;
  }
`;

const SectionLightBlue = styled(Section)`
  background: var(--lightBlue);
`;

const FeaturesGridStyled = styled(FeaturesGrid)`
  background: var(--lightBlue);
  .icon {
    fill: url(#icon-gradient) white;
  }
`;

const PageHeaderStyles = styled(PageHeader)`
  min-height: 35vh;
`;

export default HostingPage;

export const query = graphql`
  query {
    rocketDeveloper: file(relativePath: { eq: "rocket-developer.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
