import React, { Component } from 'react';
import styled from 'styled-components';
import Heading from './Styles/Heading';
import Text from './Styles/Text';
import formatMoney from '../lib/formatMoney';
import List from './List';
import ListItem from './ListItem';
import CheckIcon from './Icons/Fa/Thin/CheckIcon';
import Card from './Styles/Card';

const ColumnPricing = ({ heading, text, listItems, price }) => (
  <Wrap boxShadow={true}>
    <Heading as="h4">{heading}</Heading>
    <Price>
      {formatMoney(price)}
      <span className="price-freq">/month</span>
    </Price>
    <Text>{text}</Text>
    <List>
      {listItems.map((item) => (
        <ListItem icon={<CheckIcon />} text={item} />
      ))}
    </List>
  </Wrap>
);

const Wrap = styled(Card)``;

const Price = styled.h3`
  font-size: 3em;
  color: var(--blue);
  span {
    font-size: 1rem;
  }
`;

export default ColumnPricing;
