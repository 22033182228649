
		import React from 'react';

		function DatabaseIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 448, 512">
					<path d="M224 0c-123.7 0-224 35.82-224 80v352c0 44.18 100.3 80 224 80s224-35.82 224-80v-352C448 35.82 347.7 0 224 0zM431.1 325.7C407.3 347.7 326.5 368 223.1 368s-183.3-20.31-208-42.32V184.5C56.13 210.2 141.5 224 223.1 224s167.9-13.78 207.1-39.46V325.7zM223.1 16c126.1 0 208 37.9 208 64v85.68C407.3 187.7 326.5 208 223.1 208s-183.3-20.31-208-42.32V80C15.1 53.9 97.03 16 223.1 16zM223.1 496c-126.1 0-208-37.9-208-64v-87.46C56.13 370.2 141.5 384 223.1 384s167.9-13.78 207.1-39.46V432C431.1 458.1 350.1 496 223.1 496z" />
				</svg>
			);
		}

		export default DatabaseIcon;
	