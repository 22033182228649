
		import React from 'react';

		function ServerIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 512, 512">
					<path d="M464 288h-416C21.6 288 0 309.6 0 336v96C0 458.4 21.6 480 48 480h416c26.4 0 48-21.6 48-48v-96C512 309.6 490.4 288 464 288zM496 432c0 17.64-14.36 32-32 32h-416c-17.64 0-32-14.36-32-32v-96c0-17.64 14.36-32 32-32h416c17.64 0 32 14.36 32 32V432zM464 32h-416C21.6 32 0 53.6 0 80v96C0 202.4 21.6 224 48 224h416C490.4 224 512 202.4 512 176v-96C512 53.6 490.4 32 464 32zM496 176c0 17.64-14.36 32-32 32h-416c-17.64 0-32-14.36-32-32v-96c0-17.64 14.36-32 32-32h416c17.64 0 32 14.36 32 32V176zM352 112c-8.832 0-16 7.166-16 16s7.168 16 16 16c8.834 0 16-7.166 16-16S360.8 112 352 112zM416 112c-8.832 0-16 7.166-16 16s7.168 16 16 16c8.834 0 16-7.166 16-16S424.8 112 416 112zM352 368c-8.832 0-16 7.166-16 16c0 8.832 7.168 16 16 16c8.834 0 16-7.168 16-16C368 375.2 360.8 368 352 368zM416 368c-8.832 0-16 7.166-16 16c0 8.832 7.168 16 16 16c8.834 0 16-7.168 16-16C432 375.2 424.8 368 416 368z" />
				</svg>
			);
		}

		export default ServerIcon;
	