
		import React from 'react';

		function UserHeadsetIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 448, 512">
					<path d="M314.7 352H133.3C59.7 352 0 411.7 0 485.3C0 500.1 11.94 512 26.66 512H421.3C436.1 512 448 500.1 448 485.3C448 411.7 388.3 352 314.7 352zM421.3 496H26.66C20.78 496 16 491.2 16 485.3C16 420.6 68.64 368 133.3 368h181.3C379.4 368 432 420.6 432 485.3C432 491.2 427.2 496 421.3 496zM64 176c0-90.27 75.16-163.3 166.2-159.9C316.8 19.4 384 93.29 384 179.9V216c0 48.6-39.4 88-88 88h-28.9c4.49-7.564 7.056-16.35 2.535-27.17c-5.378-12.87-18.49-20.83-32.44-20.83L209.4 256C192.7 255.1 177.7 268.2 176.2 284.9C174.3 303.9 189.3 320 208 320h88c57.44 0 104-46.56 104-104V180.3c0-95.3-73.94-176.6-169.2-180.2C130.7-3.679 48 76.7 48 176v40C48 220.4 51.58 224 55.1 224S64 220.4 64 216V176zM240 304h-32C199.2 304 192 296.8 192 288s7.178-16 16-16h32C248.8 272 256 279.2 256 288S248.8 304 240 304zM224 80c61.75 0 112 50.25 112 112c0 24.44-7.75 47.69-22.41 67.19c-2.656 3.531-1.938 8.562 1.594 11.22C316.6 271.5 318.3 272 320 272c2.438 0 4.812-1.094 6.406-3.188C343.2 246.5 352 219.9 352 192c0-70.59-57.41-128-128-128s-128 57.41-128 128C96 219.9 104.8 246.5 121.6 268.8C124.3 272.4 129.3 273 132.8 270.4c3.531-2.656 4.25-7.688 1.594-11.22C119.8 239.7 112 216.4 112 192C112 130.3 162.3 80 224 80z" />
				</svg>
			);
		}

		export default UserHeadsetIcon;
	