export const hostingFeatures = [
  {
    heading: 'Security',
    text:
      'Providing a secure environment for your website with Regular Backups, Server Maintenance, SSL, Malware Scans, and 24/7 Monitoring.',
    icon: 'shieldAlt',
  },
  {
    heading: 'Performance',
    text:
      'We make sure your site is as fast as possible by giving you your own deticated resources on a performance optimized server.',
    icon: 'tachometerAltFastest',
  },
  {
    heading: 'Support',
    text:
      'We try to catch issues before they become a problem...but if you do need help get support, fast, from a real person, with direct access to your server.',
    icon: 'userHeadset',
  },
];

export const hostingFeaturesAll = [
  {
    heading: '24/7 Uptime Monitoring',
    text:
      'We monitor your site 24/7 for any issues, so we can start to get it back online before anyone else notices.',
    icon: 'angles-up',
  },
  {
    heading: 'Weekly Server Backups',
    text:
      "We don't just backup your website files, we back up the entire server.",
    icon: 'clock-rotate-left',
  },
  {
    heading: 'Updates',
    text:
      'Regular updates are required to ensure the performance and security of your website.',
    icon: 'arrows-rotate',
  },
  {
    heading: 'Dedicated VPS',
    text:
      'All websites are set up as their own Virtual Private Server. Stop sharing resources with 20+ other websites on the same server.',
    icon: 'server',
  },
  {
    heading: 'Hacked Website Repair',
    text:
      "If your website is ever hacked, don't panic we will have your website up and running again in no time, usually at no cost to you.",
    icon: 'shield-virus',
  },
  {
    heading: 'SSL Certificates',
    text:
      "Free SSL Certificates Powered by Let's Encrypt. No more wasting $65+ a year to secure your website.",
    icon: 'lock',
  },
];

export const hostingExtraFeatures = [
  {
    heading: 'Load Balancer',
    text:
      'Getting tons of traffic? Distribute traffic to mulitple servers to prevent downtime from too many requests overwhelming a single server.',
    icon: 'chart-network',
  },
  {
    heading: 'CDN',
    text:
      'Increase performance, relaiblity and security by using a content delivery network (CDN) to provide fast delivery of your sites files around the globe.',
    icon: 'globe',
  },
  {
    heading: 'Dedicated Database',
    text:
      'By keeping your database seprate from your webserver you increase your level of data security and flexibility.',
    icon: 'database',
  },
];

export const hostingPlans = [
  {
    heading: 'Personal',
    text: '',
    listItems: [
      'Storage - 10GB SSD',
      'Bandwidth - 100GB Monthly',
      'Weekly Full Server Backup',
      'Monthly Site Backups',
      'FREE SSL Certificate ($65+ Value)',
      'Site Migration - ($50 One-time Fee)',
    ],
    price: 5500,
  },
  {
    heading: 'Pro',
    text: '',
    listItems: [
      'Storage - 25GB SSD',
      'Bandwidth - 1000GB Monthly',
      'Weekly Full Server Backup',
      'Weekly Site Backups',
      'Hacked Website Repair',
      'FREE SSL Certificate ($65+ Value)',
      'FREE Site Migration',
    ],
    price: 8500,
  },
  {
    heading: 'Business',
    text: '',
    listItems: [
      'Storage - 80GB SSD',
      'Bandwidth - 4TB Monthly',
      'Weekly Full Server Backup',
      'Daily Site Backups',
      'Hacked Website Repair',
      'FREE SSL Certificate ($65+ Value)',
      'FREE Site Migration',
    ],
    price: 15000,
  },
];
