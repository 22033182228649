
		import React from 'react';

		function ShieldIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 512, 512">
					<path d="M466.5 83.71l-192-80C269.6 1.67 261.3 0 256 0C250.7 0 242.5 1.67 237.6 3.702l-192 80C27.7 91.1 16 108.6 16 127.1c0 257.2 189.2 384 239.1 384C307.1 511.1 496 383.8 496 127.1C496 108.6 484.3 91.1 466.5 83.71zM32 127.1c0-12.99 7.734-24.57 19.75-29.53l191.1-79.99C244.8 18.02 246.4 17.64 248 17.26v477.2C200.4 482 32 370.4 32 127.1zM264 494.4V17.24c1.605 .3828 3.301 .7695 4.344 1.205l192 80.02C472.3 103.4 480 114.1 480 127.1C480 401 277.5 490.8 264 494.4z" />
				</svg>
			);
		}

		export default ShieldIcon;
	