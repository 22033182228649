import React, { Component } from 'react';
import styled from 'styled-components';

import Section from './Section';
import Inner from './Styles/Inner';
import Grid from './Styles/Grid';
import Typography from './Styles/Typography';
import Text from './Styles/Text';
import Box from './Styles/Box';
import Card from './Styles/Card';

export class Section3col extends Component {
  render() {
    return (
      <Section id="pricing">
        <Typography as="h2" textAlign="center">
          Managed Web Hosting Pricing
        </Typography>
        <Grid gridTemplateColumns={['1fr', '1fr 1fr 1fr']} gridGap={2}>
          {this.props.children}
        </Grid>
        <Box></Box>
      </Section>
    );
  }
}

export default Section3col;
