
		import React from 'react';

		function GaugeMaxIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 576, 512">
					<path d="M96 336c-8.836 0-16 7.162-16 16c0 8.836 7.164 16 16 16s16-7.164 16-16C112 343.2 104.8 336 96 336zM288 144c8.836 0 16-7.164 16-16c0-8.838-7.164-16-16-16S272 119.2 272 128C272 136.8 279.2 144 288 144zM144 176C135.2 176 128 183.2 128 192c0 8.836 7.164 16 16 16S160 200.8 160 192C160 183.2 152.8 176 144 176zM432 208C440.8 208 448 200.8 448 192c0-8.838-7.164-16-16-16S416 183.2 416 192C416 200.8 423.2 208 432 208zM494.5 336.1l-132.1 25.4C346.9 336.7 319.4 320 288 320c-48.53 0-88 39.47-88 87.1C200 412.4 203.6 416 208 416s8-3.578 8-8c0-39.7 32.31-72 72-72s72 32.3 72 72c0 4.422 3.594 8 8 8s8-3.578 8-8c0-11.17-2.301-21.76-6.109-31.6l127.6-24.54c4.344-.8438 7.188-5.031 6.344-9.375C503 338.2 498.8 335.4 494.5 336.1zM288 32c-158.8 0-288 129.2-288 288c0 55.09 16.25 109.2 46.94 156.4C48.44 478.6 50.94 480 53.66 480h468.7c2.719 0 5.219-1.375 6.719-3.641C559.8 429.2 576 375.1 576 320C576 161.2 446.8 32 288 32zM517.1 464H58.03C30.53 420.3 16 370.6 16 320c0-149.1 122-272 272-272s272 122 272 272C560 370.6 545.5 420.3 517.1 464z" />
				</svg>
			);
		}

		export default GaugeMaxIcon;
	