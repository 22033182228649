
		import React from 'react';

		function GlobeIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 512, 512">
					<path d="M256 16C123.5 16 16 123.5 16 256s107.5 240 240 240s239.1-107.5 239.1-240S388.5 16 256 16zM464.1 176h-103.5c-10.3-62.32-32.19-112.8-60.2-139.4C376.6 52.13 437.9 105.6 464.1 176zM352 256c0 22.63-1.578 43.98-4.274 64H164.3C161.6 299.1 160 278.6 160 256s1.578-43.98 4.273-64h183.5C350.4 212 352 233.4 352 256zM256 480c-35.5 0-74.05-56.38-89.24-144h178.5C330.1 423.6 291.5 480 256 480zM166.8 176C181.9 88.38 220.5 32 256 32s74.06 56.38 89.24 144H166.8zM210.7 36.61C182.7 63.21 160.8 113.7 150.5 176H47.02C74.07 105.6 135.4 52.13 210.7 36.61zM41.43 192h106.7C145.5 212.4 144 233.8 144 256s1.516 43.6 4.148 64H41.43C35.37 299.7 32 278.2 32 256S35.37 212.3 41.43 192zM47.02 336h103.5c10.3 62.32 32.19 112.8 60.2 139.4C135.4 459.9 74.07 406.4 47.02 336zM301.3 475.4c28.01-26.6 49.9-77.07 60.2-139.4h103.5C437.9 406.4 376.6 459.9 301.3 475.4zM470.6 320h-106.7C366.5 299.6 368 278.2 368 256s-1.516-43.6-4.148-64h106.7C476.6 212.3 480 233.8 480 256S476.6 299.7 470.6 320z" />
				</svg>
			);
		}

		export default GlobeIcon;
	